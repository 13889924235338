import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const fbConfig = JSON.parse(`{"apiKey":"AIzaSyBeKBgXWE9LSslzZ5rpUFzmlo0SqfqsbrQ","authDomain":"chadez-dbc8e.firebaseapp.com","projectId":"chadez-dbc8e","storageBucket":"chadez-dbc8e.appspot.com","messagingSenderId":"969864594897","appId":"1:969864594897:web:8b19abb7b75bf7db070cf0"}`);
const app = firebase.initializeApp(fbConfig);
export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const fieldValue = firebase.firestore.FieldValue;
export const authFunc = firebase.auth;
export const functions = app.functions("us-central1");
export default app;
